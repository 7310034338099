
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue {
  private hoy = new Date();
  private fecha = this.hoy.getFullYear();

  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {});
      }
    }
  }

  private irLink(link: any) {
    window.open(link, "_blank");
  }
}
