
import { Component, Vue, Watch } from "vue-property-decorator";
import { Notify } from "quasar";
import LoginComponent from "@/components/auth/LoginComponent.vue";
import RecoverComponent from "@/components/auth/RecoverComponent.vue";
import ResendComponent from "@/components/auth/ResendComponent.vue";

@Component({
  components: {
    ResendComponent,
    RecoverComponent,
    LoginComponent
  }
})
export default class HeaderComponent extends Vue {
  private drawer: boolean = false;
  private miniState: boolean = true;
  private logged: boolean = false;

  private win = window;
  private base = window.location;
  private left = false;
  private modalAuth = false;
  private showLogin = true;
  private showRecover = false;
  private showResend = false;
  private dataCategoria: Array<any> = [];

  get token() {
    return this.$store.state.token;
  }

  @Watch("$store.state.user")
  private userWatch(newValue: any) {
    if (newValue && newValue.id && newValue.id != "") {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }

  @Watch("$store.state.authModal")
  private authModalWatch(newValue: any) {
    this.modalAuth = newValue;
  }
  @Watch("$store.state.token.auth_token")
  private tokenWatch(newValue: any) {
    if (this.$store.state.token.auth_token) {
      this.$axios.defaults.headers.common.Authorization = `${this.token.auth_token}`;
      this.$axios("user")
        .then(res => {
          this.$store.dispatch("GET_USER", res.data).then(() => {
            this.$i18n.locale = this.$store.state.user.lenguage;
            this.logged = true;
          });
          this.logged = true;
          this.$store.commit("LOADING", false);
        })
        .catch();
    } else {
      this.$store.commit("LOADING", false);
    }
    if (
      this.$store.state.user &&
      this.$store.state.user.id &&
      this.$store.state.user.id != ""
    ) {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }

  private mounted() {
    this.$store.commit("SET_AUTHMODAL", false);
    if (this.$store.state.token.auth_token) {
      this.$axios.defaults.headers.common.Authorization = `${this.token.auth_token}`;
      this.$axios("user")
        .then(res => {
          this.$store.dispatch("GET_USER", res.data).then(() => {
            this.$i18n.locale = this.$store.state.user.lenguage;
            this.logged = true;
          });
          this.logged = true;
          this.$store.commit("LOADING", false);
        })
        .catch();
    } else {
      this.$store.commit("LOADING", false);
    }
    if (
      this.$store.state.user &&
      this.$store.state.user.id &&
      this.$store.state.user.id != ""
    ) {
      this.logged = true;
    } else {
      this.logged = false;
    }
    this.getCategorias();
  }

  private getCategorias() {
    this.$axios
      .get("categorias")
      .then((res: any) => {
        const responseData = res.data;
        responseData.forEach(c => {
          this.dataCategoria.push({
            value: c.id,
            label: c.nombre,
            nombreWeb: c.nombre_web
          });
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {});
      }
    }
  }

  private irCategoria(nombreWeb: any) {
    if (nombreWeb && nombreWeb != "") {
      if (
        this.$router.currentRoute.name == "Categorias" &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombreWeb
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "Categorias") {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombreWeb
          ) {
            this.$router
              .push({ name: "Categorias", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: "Categorias", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: "Categorias", params: { nombre: nombreWeb } })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }
  private logout() {
    this.logged = false;
    this.$store.commit("LOGOUT");
    if (this.$router.currentRoute.name == "Talleres") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "Talleres" }).catch(err => {});
    }
  }

  private lenguage(value) {
    this.$store.commit("SET_LENGUAGE", value);
    this.$i18n.locale = value;
  }

  private updateData(value) {
    this.$axios
      .put("user/idioma", {
        lang: value
      })
      .then(() => {
        this.$store.commit("SET_LENGUAGE", value);
        this.$i18n.locale = value;
      })
      .catch(() => {
        this.$store.commit("SET_LENGUAGE", value);
        this.$i18n.locale = value;
      })
      .finally(() => {
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      });
  }

  private ruta() {
    this.$router.push({ path: "/" }).catch(err => {});
  }
  private verLogin() {
    this.showLogin = true;
    this.showRecover = false;
    this.showResend = false;
    this.$store.commit("SET_AUTHMODAL", true);
  }
  private onLogin(info) {
    this.modalAuth = false;
    if (info.status == "success") {
      if (this.$router.currentRoute.name == "MisTalleres") {
        this.$router.go(0);
      } else if (this.$router.currentRoute.name == "PerfilTaller") {
        this.$router.go(0);
      } else {
        this.$router.push({ name: "MisTalleres" }).catch(err => {});
      }
    }
  }
  private onLoginRecover(info) {
    this.showLogin = false;
    this.showRecover = true;
    this.showResend = false;
  }
  private onLoginResend(info) {
    this.showLogin = false;
    this.showRecover = false;
    this.showResend = true;
  }
  private onRecover(info) {
    this.showLogin = true;
    this.showRecover = false;
    this.showResend = false;
    this.modalAuth = true;
  }
  private onResend(info) {
    this.showLogin = true;
    this.showRecover = false;
    this.showResend = false;
    this.modalAuth = true;
  }
  private beforeCloseAuth() {
    this.$store.commit("SET_AUTHMODAL", false);
    this.showLogin = true;
    this.showRecover = false;
    this.showResend = false;
  }
}
