
import { Component, Vue } from "vue-property-decorator";
import HeaderComponent from "@/components/Common/HeaderComponent.vue"; // @ is an alias to /src
import FooterComponent from "@/components/Common/FooterComponent.vue"; // @ is an alias to /src

@Component({
  components: {
    HeaderComponent,
    FooterComponent
  }
})
export default class BaseLayout extends Vue {}
