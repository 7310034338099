
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface LoginData {
  rut: string;
  password: string;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {}
})
export default class LoginComponent extends Mixins(FormValidator) {
  private formattedRut = "";
  private dataLogin: LoginData = {
    rut: "",
    password: ""
  };
  private datosTipoIdentificacion: Tipos = {
    value: 1,
    label: "Cédula de identidad (Rut)"
  };
  private selectTipoIdentificacion: Array<any> = [
    { value: 1, label: "Cédula de identidad (Rut)" },
    { value: 2, label: "Otro" }
  ];
  private tipoDocumento = 1;
  private disabled = true;

  @Watch("datosTipoIdentificacion")
  private identificacionTipo(newValue: any) {
    this.tipoDocumento = newValue.value;
    this.dataLogin.rut = "";
    this.formattedRut = "";
    this.disabled = false;
  }

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    this.dataLogin.rut = newValue;
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  private loading: boolean = false;
  private login() {
    let rut = "";
    if (this.tipoDocumento == 1) {
      rut = this.rutSanitize(this.dataLogin.rut);
      if (!this.validateRut(rut)) {
        Notify.create({
          color: "negative",
          message: this.$t("notifications.error.NO_RUT").toString(),
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
    } else {
      rut = this.dataLogin.rut;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    const credentials = { rut, password: this.dataLogin.password };
    this.loading = true;
    this.$axios
      .post("auth", credentials)
      .then(res => {
        this.$q.loading.hide();
        this.$store.commit("SET_TOKEN", res.data);
        this.$axios.defaults.headers.common.Authorization = `${res.data.auth_token}`;
        localStorage.setItem("auth_token", res.data.auth_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        this.$axios("user")
          .then(res => {
            this.$store.dispatch("GET_USER", res.data).then(() => {
              this.$i18n.locale = this.$store.state.user.lenguage;
              this.$emit("login", {
                status: "success",
                response: true
              });
            });
          })
          .catch();
      })
      .catch(error => {
        const message = error.response.data.message;
        const type = error.response.data.type;
        if (message === "invalid_grant") {
          // Contraseña incorrecta
          Notify.create({
            color: "negative",
            message: this.$t("notifications.error.WRONG_PASS").toString(),
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (message === "not_registered") {
          // No registrad0
          Notify.create({
            color: "negative",
            message: this.$t("notifications.error.UNREGISTRED").toString(),
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (message === "EMAIL_NOT_VERIFY") {
          // No registrad0
          Notify.create({
            color: "negative",
            message: this.$t("notifications.error.USER_UNCONFIRMED").toString(),
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: this.$t("notifications.error.CREDENTIALS").toString(),
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      })
      .finally(() => {
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private registrar() {
    if (this.$router.currentRoute.name == "registro") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "registro" }).catch(err => {});
    }
  }

  private recuperar() {
    this.$emit("recover", {
      status: "success",
      response: true
    });
  }

  private reenviar() {
    this.$emit("resend", {
      status: "success",
      response: true
    });
  }

  private rutSanitize(rut: string) {
    const temp = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = temp.substring(0, temp.length - 1);
    const dv = temp.substring(temp.length - 1);
    return `${rutNumber}-${dv}`;
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
